import ImageSwap from '../ImageSwap';
import PixelsSearch from '../PixelsSearch';
import PlaylistVideoList from '../playlist-detail/PlaylistVideoList';
import Playlist from '../Playlist';
import PromotedContentList from '../PromotedContentList';
import PromotedContentReport1 from '../PromotedContentReport1';
import PromotedContentReport2 from '../PromotedContentReport2';
import Report from '../Report';
import PVReport from '../PVReport';
import VideoLibrary from '../VideoLibrary';
import GapAnalysisReport from '../GapAnalysisReport';
import PlaylistTrendingArticle from '../playlist-detail/PlaylistTrendingArticle';
import PlaylistPreview from '../playlist-detail/PlaylistPreview';

export default function MainSections({ section }) {
  return (
    <>
      {section === 'report' && (
        <Report/>
      )}
      {section === 'pv-report' && (
        <PVReport/>
      )}
      {section === 'video-swap' && (
        <ImageSwap
          type="review"
          title="Fast Match"
        />
      )}
      {section === 'video-swap-no-matches' && (
        <ImageSwap
          type="no-matches"
          title="Needs better video"
        />
      )}
      {section === 'video-swap-swapped' && (
        <ImageSwap
          type="swapped"
          title="Fast Match"
        />
      )}
      {section === 'video-swap-improve' && (
        <ImageSwap
          type="improve"
          title="Fast Match"
        />
      )}
      {section === 'promoted-content-1' && (
        <PromotedContentReport1/>
      )}
      {section === 'promoted-content-2' && (
        <PromotedContentReport2/>
      )}
      {section === 'promoted-content-3' && (
        <PromotedContentList />
      )}
      {section === 'headline-search' && (
        <PixelsSearch />
      )}
      {section === 'video-library' && (
        <VideoLibrary />
      )}
      {section === 'playlist' && (
        <Playlist/>
      )}
      {section === 'playlist-video' && (
        <PlaylistVideoList />
      )}
      {section === 'playlist-popular-article' && (
        <PlaylistTrendingArticle />
      )}
      {section === 'playlist-preview' && (
        <PlaylistPreview />
      )}
      {section === 'gap-analysis' && (
        <GapAnalysisReport
        />
      )}
    </>
  );
}
