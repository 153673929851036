import {
  Select,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  InputLabel, OutlinedInput, Radio, RadioGroup,
} from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { useContext, useState } from 'react';
import Button from '@mui/material/Button';

import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { IABCategory, PlaylistFormData, PlaylistPublisher } from '../types/playlist';
import { OrganisationContext } from '../../App';

const CloseBtnRoot = styled('div')`
    position: absolute;
    right: 10px;
    top: 10px
`;

const Root = styled('div')`
    padding: 20px 40px 0;
    position: relative;
`;

const Title = styled('div')`
    text-transform: capitalize;
    font-weight: bold;
    //margin: 24px 0 0 0;
`;

function getStyles<T>(name: T, codes: T[], theme: Theme) {
  return {
    fontWeight:
      codes.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : 'bold',
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      maxWidth: 250,
    },
  },
};

const stateChange = (key, setValue, state: PlaylistFormData) => ({ target: { type, value } }) => {
  setValue({
    ...state,
    [key]: /datetime-local|number/.test(type) && value ? Number(value) : value
  });
};

export const EditPlaylistDialog = ({
  open,
  data,
  playlistTypes,
  onSave,
  handleClose,
  iabCategories,
  playlistPublishers,
  geoCountries
}: {
  onSave: (x: PlaylistFormData) => void,
  open: boolean,
  handleClose: () => void,
  data: PlaylistFormData,
  playlistTypes: { id: number, playlistType: string }[]
  iabCategories: IABCategory[]
  playlistPublishers: PlaylistPublisher[]
  geoCountries: { id: number, country: string, alpha_2_code: string}[]
}) => {
  const theme = useTheme();
  const { isAdmin } = useContext(OrganisationContext);
  const [updatedData, setUpdatedData] = useState<PlaylistFormData>(data);
  const selectedTypeObj = playlistTypes.find(({ id }) => updatedData['type'] === id);
  return (<Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <Root>
        <CloseBtnRoot>
          <IconButton onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
        </CloseBtnRoot>
        <Title>Playlist Details</Title>
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField id="name" label="Name" variant="outlined"
                           required
                           type={'text'}
                           onChange={stateChange('name', setUpdatedData, updatedData)}
                           value={updatedData.name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="format">Format</InputLabel>
                <Select
                  labelId="format"
                  id="formatSelection"
                  value={updatedData['format']}
                  onChange={(e: SelectChangeEvent<string>) => {
                    setUpdatedData({
                      ...updatedData,
                      format: e.target.value
                    });
                  }}
                  input={<OutlinedInput label="Name"/>}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value={'vertical'}
                  >
                    vertical
                  </MenuItem>
                  <MenuItem
                    value={'horizontal'}
                  >
                    horizontal
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="type">Type</InputLabel>
                <Select
                  multiple={false}
                  labelId="type"
                  id="typeSelection"
                  value={updatedData['type']}
                  defaultValue={data.type}
                  onChange={(e: SelectChangeEvent<number>) => {
                    setUpdatedData({
                      ...updatedData,
                      type: Number(e.target.value)
                    });
                  }}
                  input={<OutlinedInput label="Name"/>}
                  MenuProps={MenuProps}
                >
                  {playlistTypes.map(({ id, playlistType }) => <MenuItem
                    value={id} key={id}
                  >{playlistType}
                  </MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            {selectedTypeObj?.playlistType.toLowerCase().includes('mrss') && <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField id="mrssUrl" label="MRSS URL" variant="outlined"
                             type={'url'}
                             onChange={stateChange('mrssUrl', setUpdatedData, updatedData)}
                             value={updatedData.mrssUrl}
                    // error={vIdHasBeenTouch && Boolean(errors.videoId)}
                    // helperText={vIdHasBeenTouch && errors.videoId}
                    // onKeyPress={() => !vIdHasBeenTouch && setVIdHasBeenTouch(true)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField id="password" label="MRSS Password" variant="outlined"
                             type={'text'}
                             onChange={stateChange('mrssPassword', setUpdatedData, updatedData)}
                             value={updatedData.mrssPassword}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField id="username" label="MRSS Username" variant="outlined"
                             type={'text'}
                             onChange={stateChange('mrssUsername', setUpdatedData, updatedData)}
                             value={updatedData.mrssUsername}
                  />
                </FormControl>
              </Grid>
            </>}

            {isAdmin && updatedData.type === 1 && <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="type">Refresh interval</InputLabel>
                  <Select
                    multiple={false}
                    labelId="type"
                    id="refreshRateInMins"
                    value={updatedData['refreshRateInMins']}
                    defaultValue={Math.floor(data.refreshRateInMins)}
                    onChange={(e: SelectChangeEvent<number>) => {
                      setUpdatedData({
                        ...updatedData,
                        refreshRateInMins: Number(e.target.value)
                      });
                    }}
                    input={<OutlinedInput label="Name"/>}
                    MenuProps={MenuProps}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12].map((hour) => <MenuItem
                      value={hour * 60}
                    >every {hour} hour{hour > 1 ? 's' : ''}
                    </MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>


              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="iabCategorySelection">IAB Categories</InputLabel>
                  <Select
                    labelId="iabCategorySelection"
                    id="iabCategorySelection-selection"
                    label={'Assign iab category'}
                    multiple
                    value={updatedData.iabCategories}
                    onChange={(e: SelectChangeEvent<string[]>) => {
                      setUpdatedData({
                        ...updatedData,
                        iabCategories: e.target.value as string[]
                      });
                    }}
                    input={<OutlinedInput label=">Assign rights holders"/>}
                    MenuProps={MenuProps}
                  >
                    {iabCategories.map(({ id, name, level }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        style={getStyles(id, data.iabCategories, theme)}
                      >
                        {level > 1 ? `${[...Array(level).keys()].map(() => '- ').join('')} ${name}` : name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} paddingTop={0} style={{ paddingTop: 0 }}>
                <FormControl fullWidth>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              row>
                    <FormControlLabel
                      value="incluceIabCategory"
                      control={<Radio checked={!updatedData.iabCategoriesExclude}
                                      onChange={e => setUpdatedData({
                                        ...updatedData,
                                        iabCategoriesExclude: false,
                                      })}/>}
                      label="Include"
                    />
                    <FormControlLabel
                      value="excludeIabCategory"
                      control={<Radio checked={updatedData.iabCategoriesExclude}
                                      onChange={e => setUpdatedData({
                                        ...updatedData,
                                        iabCategoriesExclude: true,
                                      })}/>}
                      label="Exclude"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="iabCategorySelection">Publishers</InputLabel>
                  <Select
                    labelId="playlistPublisher"
                    id="playlistPublisher"
                    label={'Assign publisher'}
                    multiple
                    value={updatedData.playlistPublisher}
                    onChange={(e: SelectChangeEvent<string[]>) => {
                      setUpdatedData({
                        ...updatedData,
                        playlistPublisher: e.target.value as string[]
                      });
                    }}
                    input={<OutlinedInput label=">Assign rights holders"/>}
                    MenuProps={MenuProps}
                  >
                    {playlistPublishers.map(({ id, name }) => (
                      <MenuItem
                        key={id}
                        value={String(id)}
                        style={getStyles<string>(String(id), updatedData.playlistPublisher, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <FormControl fullWidth>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              row>
                    <FormControlLabel
                      value="includePublishers"
                      control={<Radio checked={!updatedData.playlistPublisherExclude}
                                      onChange={e => setUpdatedData({
                                        ...updatedData,
                                        playlistPublisherExclude: false,
                                      })}/>}
                      label="Include"
                    />
                    <FormControlLabel
                      value="excludePublishers"
                      control={<Radio checked={updatedData.playlistPublisherExclude}
                                      onChange={e => setUpdatedData({
                                        ...updatedData,
                                        playlistPublisherExclude: true,
                                      })}/>}
                      label="Exclude"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="iabCategorySelection">Geo country</InputLabel>
                  <Select
                    labelId="geoCountry"
                    id="geoCountry"
                    label={'Assign geo country'}
                    multiple
                    value={updatedData.geoCountries as unknown as string[]}
                    onChange={(e: SelectChangeEvent<string[]>) => {
                      setUpdatedData({
                        ...updatedData,
                        geoCountries: e.target.value as unknown as number[]
                      });
                    }}
                    input={<OutlinedInput label=">Assign geo country"/>}
                    MenuProps={MenuProps}
                  >
                    {geoCountries.map(({ id, country }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        style={getStyles<number>(id, updatedData.geoCountries, theme)}
                      >
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <FormControl fullWidth>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              row>
                    <FormControlLabel
                      value="includeGeoCountries"
                      control={<Radio checked={!updatedData.geoCountriesExclude}
                                      onChange={e => setUpdatedData({
                                        ...updatedData,
                                        geoCountriesExclude: false,
                                      })}/>}
                      label="Include"
                    />
                    <FormControlLabel
                      value="excludeGeoCountries"
                      control={<Radio checked={updatedData.geoCountriesExclude}
                                      onChange={e => setUpdatedData({
                                        ...updatedData,
                                        geoCountriesExclude: true,
                                      })}/>}
                      label="Exclude"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Checkbox
                    onChange={({ target: { checked } }) => setUpdatedData({
                      ...updatedData,
                      isActive: checked
                    })}
                    checked={updatedData.isActive}
                  />}
                  label="Enable"
                />
              </Grid>
            </>}
            <Grid item xs={12}>
              <Grid container justifyContent={'flex-end'}>
                <Button color="primary" variant="contained" disabled={!updatedData.name}
                        onClick={() => onSave(updatedData)}>Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Root>
    </Dialog>
  );
};