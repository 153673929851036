import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from '../common/Table';
import TableBody from '@mui/material/TableBody';
import { BoldTypography, BoldTypographyLarge } from '../common/TextLabel';
import Moment from 'react-moment';
import Table from '@mui/material/Table';
import { decode } from 'he';
import { capitalise } from '../../utils/common';
import plusButton from '../../icons/plus.png';
import removeButton from '../../icons/remove.png';
import { ReactComponent as PencilIcon } from '../../icons/pencil.svg';
import { Grid } from '@mui/material';
import { PlaylistVideo } from '../types/playlist';

export const PlaylistVideoTable = ({
  videos,
  onSetVideoPreview,
  onSetAddVideoPreview,
  onSetEditVideoPreview,
  onRemoveVideo,
  format,
}: {
  format: string,
  videos: PlaylistVideo[],
  onSetVideoPreview: (data: PlaylistVideo) => void,
  onSetEditVideoPreview: (data: PlaylistVideo) => void,
  onSetAddVideoPreview: (data: PlaylistVideo | { pos: number }) => void,
  onRemoveVideo: (id: number) => void,
}) => {
  const nowTimestamp = Math.floor(new Date().getTime() / 1000);
  const isPublisherView = localStorage.getItem('isPublisherView') === 'true';
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>PLAYS</TableCellHead>
          <TableCellHead>VIDEO</TableCellHead>
          <TableCellHead>DATE</TableCellHead>
          <TableCellHead sx={{minWidth: '280px'}}>TITLE</TableCellHead>
          <TableCellHead>RIGHTSHOLDER</TableCellHead>
          <TableCellHead>DURATION</TableCellHead>
          <TableCellHead>ARTICLE</TableCellHead>
          <TableCellHead sx={{ whiteSpace: 'nowrap'}}>PUBLISH DATE</TableCellHead>
          <TableCellHead sx={{ whiteSpace: 'nowrap'}}>ADDED DATE</TableCellHead>
          {!isPublisherView && <TableCellHead width="160">TOOLS</TableCellHead>}
        </TableRow>
      </TableHead>
      <TableBody>
        {videos.length === 0 && (
          <TableRow>
            <TableCellBody colSpan={9} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>This playlist contains no videos yet</div>
            </TableCellBody>
            {!isPublisherView && (
              <TableCellBody sx={{ minWidth: '200px' }}>
                <span onClick={() => onSetAddVideoPreview({ pos: nowTimestamp })} style={{ cursor: 'pointer' }}>
                  <img width="28" src={plusButton} alt="plus button" />
                </span>
              </TableCellBody>
            )}
          </TableRow>
        )}
        {videos &&
          videos.map(row => (
            <TableRow
              sx={{
                height: '60px',
                backgroundColor: row.video_format !== format ? '#f2feff' : (row.has_trending_matched ? 'lightyellow' : 'transparent')
              }}
              key={row.id}
            >
              <TableCellBody align="center">{row.video_play_count.toLocaleString()}</TableCellBody>
              <TableCellBody
                sx={{ width: '104px' }}
                onClick={() => {
                  onSetVideoPreview(row);
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${row.video_thumbnail || '/bg-grey.png'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5%',
                    aspectRatio: format === 'vertical' ? '9 / 16' : '4 / 3'
                  }}
                ></div>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>
                  <Moment format="Do MMM">{row.video_publish_date}</Moment>
                </BoldTypography>
              </TableCellBody>
              <TableCellBody><span title={row.video_title}>{decode(row.video_title_translated)}</span></TableCellBody>
              <TableCellBody sx={{ minWidth: '150px' }}>
                <BoldTypographyLarge>{capitalise(row.video_rightsholder)}</BoldTypographyLarge>
              </TableCellBody>
              <TableCellBody>{row.video_duration}</TableCellBody>
              <TableCellBody>
                { !row.is_manually_added && <div
                  title={`${row.article_title}\n\nPage view total: ${row.total_page_views}`}
                  style={{
                    backgroundImage: `url(${row.article_thumbnail || '/bg-grey.png'})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '5%',
                    aspectRatio: '4 / 3',
                    width: '100px'
                  }}
                ></div>}
              </TableCellBody>
              <TableCellBody>
                { !row.is_manually_added && <BoldTypography>
                  <Moment format="Do MMM">{row.article_publish_date}</Moment>
                </BoldTypography>}
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>
                  <Moment format="Do MMM">{row.record_inserted_at}</Moment>
                </BoldTypography>
              </TableCellBody>
              {!isPublisherView && (
                <TableCellBody sx={{ minWidth: '200px' }}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                      <span onClick={() => onSetAddVideoPreview(row)} style={{ cursor: 'pointer' }}>
                        <img width="28" src={plusButton} alt="plus button" />
                      </span>
                    </Grid>
                    <Grid item>
                      <PencilIcon style={{ cursor: 'pointer' }} onClick={() => {
                        onSetEditVideoPreview(row)
                      }} />
                    </Grid>
                    <Grid item>
                      <span onClick={() => onRemoveVideo(row.id)} style={{ cursor: 'pointer' }}>
                        <img width="28" src={removeButton} alt="remove button" />
                      </span>
                    </Grid>
                  </Grid>
                </TableCellBody>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
